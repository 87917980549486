import Vivus from 'vivus';
import { slick } from 'slick-carousel';

document.addEventListener('DOMContentLoaded', (e) => {

	const cards = document.querySelectorAll('.block--icon-cards .icon-card');
	const blocks = document.querySelectorAll('.block--icon-cards');
	const config = { rootMargin: '0px 0px',	threshold: 0 };

	const block_icon_cards = {

		init: (block) => {

			let cards = block.querySelectorAll('.icon-cards .icon-card');
			block_icon_cards.animateIcons(cards);
			block_icon_cards.slick(block);


		},
		
		blockIntersection: (entries) => {
			
			entries.forEach( entry => {
				if ( entry.intersectionRatio > 0 ) {
					blockObserver.unobserve( entry.target );
					// block_icon_cards.animate( entry.target );
				}
			});		
		},

		animateIcons: (cards) => {

			cards.forEach( card => {

				let icon = card.querySelector('.icon');

				if ( icon.classList.contains('animate')) {
					let div = card.querySelector('.icon');
					let use = div.querySelector('use');
					let symbol = document.querySelector(use.getAttribute('xlink:href'));
					let icon = document.createElement('svg');

					if( symbol ) {
						Array.from(symbol.attributes).forEach(attr => {
							if( attr.name != 'id') {
								icon.setAttribute(attr.name, attr.value);
							}
						});

						icon.innerHTML = symbol.innerHTML;
						div.innerHTML = icon.outerHTML;

						icon = div.querySelector('svg');
						block_icon_cards.vivus(icon);
					}				
				}

			})
		},

		vivus: (icon) => {

			new Vivus(icon, {
				type: 'oneByOne',
				duration: 100,
				onReady: function() {
					icon.style.opacity = 1;
				}
			});
		},

		slick: (block) => {

			const target = block.querySelector('.icon-cards');

			if ( target.classList.contains('mobile-slider') ) {

				const options = {
					slidesToShow: 999,
					arrows: false,
					dots: false,
					autoplay: false,
					infinite: true,
				};

				if ( target.classList.contains('one-col') ) {

					options.responsive = [
						{
							breakpoint: 991,
							settings: {
								slidesToShow: 1,
								slidesToScroll: 1,
								arrows: true,
								dots: true, 
								centerMode: true,
								centerPadding: '12px',
								appendArrows: '#' + block.id + ' .slider-nav',
								appendDots: '#' + block.id + ' .slider-nav'
							}
						}
					];
				} else {

					options.responsive = [
						{
							breakpoint: 768,
							settings: {
								slidesToShow: 1,
								slidesToScroll: 1,
								arrows: true,
								dots: true, 
								centerMode: true,
								centerPadding: '12px',
								appendArrows: '#' + block.id + ' .slider-nav',
								appendDots: '#' + block.id + ' .slider-nav'
							}
						}
					];

				}
			
				let items = target.querySelectorAll('.icon-card');
			
				if(items.length > 1) {
					$(target).slick(options);
				}
			}
		},
		
		animate: (block) => {
			block.classList.add('animate');
			
			setTimeout(function() {
				cards.forEach( card => {
					cardObserver.observe( card );
				});
			}, 1000);
		},
		
		log: () => {
			console.log('test');
		}
	}

	let blockObserver = new IntersectionObserver( block_icon_cards.blockIntersection, config );

	blocks.forEach( block => {
		block_icon_cards.init(block);
		blockObserver.observe( block );
	});
});